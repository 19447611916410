@charset "UTF-8";
@media (max-width: 991px) {
  #news-list-homepage {
    margin-top: 3em;
  }
}

.news-repeater a.news-item {
  display: block;
  line-height: 1.1;
  margin-bottom: 1em;
  text-decoration: none;
}

.news-repeater a.news-item span.title {
  display: inline-block;
  font-size: 16px;
  color: #21282d;
  transition: all 0.15s ease-in-out;
  border-bottom: 1px solid transparent;
  margin-bottom: .25em;
}

.news-repeater a.news-item span.meta {
  display: block;
  font-size: 13.6px;
  color: #8597a4;
  font-weight: 300;
}

.news-repeater a.news-item:hover,
.news-repeater a.news-item:focus,
.news-repeater a.news-item:active {
  text-decoration: none;
}

.news-repeater a.news-item:hover span.title,
.news-repeater a.news-item:focus span.title,
.news-repeater a.news-item:active span.title {
  border-bottom-color: #21282d;
}

.news-article h2 {
  font-size: 1.5em;
  margin-bottom: .25em;
}

.news-article h4 {
  margin-top: 0;
  color: #8597a4;
  font-size: .85em;
}

.news-article .news-content {
  font-size: .9em;
}

section.careers-hero {
  text-align: center;
  background: #0081ab center center no-repeat;
  background-size: cover;
  padding: 3em 0;
}

section.careers-hero h1, section.careers-hero h2, section.careers-hero h3, section.careers-hero h4, section.careers-hero h5, section.careers-hero p {
  color: #FFF;
  font-weight: 200;
}

section.careers-hero h1 {
  font-size: 36px;
  margin: 0;
}

section.careers-hero p {
  font-size: 18px;
}

@media (min-width: 768px) {
  section.careers-hero {
    padding: 5em 0;
  }
}

@media (max-width: 767px) {
  section.careers-hero h1 {
    font-size: 24px;
  }
  section.careers-hero p {
    font-size: 16px;
    width: 75%;
    margin-top: .5em;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.25;
  }
}

.homepage-hero, .services-hero, .lending-hero {
  padding: 4em 0;
}

.homepage-hero h1, .services-hero h1, .lending-hero h1 {
  font-size: 36px;
  font-weight: 200;
  margin: 0 0 0.25em 0;
}

.homepage-hero h1 + p, .services-hero h1 + p, .lending-hero h1 + p {
  font-size: 20px;
  font-weight: 200;
  line-height: 1.25;
}

@media (max-width: 767px) {
  .homepage-hero h1, .services-hero h1, .lending-hero h1 {
    font-size: 32px;
  }
  .homepage-hero h1 + p, .services-hero h1 + p, .lending-hero h1 + p {
    margin-bottom: 1.5em;
  }
}

.corp-about-hero {
  padding: 3em 0;
}

.corp-about-hero h2 {
  margin-top: 0;
}

.services-hero {
  background: #f8f8f7;
}

.services-hero h3 {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 1em;
}

.services-hero h3 ~ p {
  font-weight: 200;
}

@media (max-width: 767px) {
  .services-hero h3 ~ p {
    line-height: 1.33;
  }
  .services-hero .btn-read-more {
    margin-bottom: 1em;
    margin-top: -.25em;
  }
}

.lending-hero {
  padding: 4em 0 3em 0;
}

.lending-hero a {
  display: inline-block;
  padding: 10px 0;
}

@media (max-width: 991px) {
  .lending-hero a {
    margin-right: 1em;
    margin-bottom: 1em;
  }
  .lending-hero a:nth-of-type(4),
  .lending-hero a:nth-of-type(5),
  .lending-hero a:nth-of-type(6) {
    margin-bottom: 0;
  }
}

.make-striped-links .list-group-item, .title-date-list .list-group-item, .file-list .list-group-item {
  padding: 0;
}

.make-striped-links .list-group-item:nth-of-type(odd), .title-date-list .list-group-item:nth-of-type(odd), .file-list .list-group-item:nth-of-type(odd) {
  background: #fbfafa;
}

.make-striped-links a, .title-date-list a, .file-list a {
  padding: 6px 15px;
  display: block;
  font-size: 15px;
}

.title-date-list .title {
  font-weight: 500;
}

.title-date-list .date {
  font-weight: 300;
  color: #627685;
}

@media (min-width: 768px) {
  .title-date-list .date {
    float: left;
    width: 100px;
  }
}

@media (max-width: 767px) {
  .title-date-list .date {
    display: block;
    font-size: 14px;
  }
}

.file-list .fa {
  display: inline-block;
  width: 1em;
  margin-right: .5em;
}

.annual-report {
  margin-bottom: 1.5em;
}

.annual-report > a {
  float: left;
  width: 90px;
}

.annual-report > a img {
  width: 100%;
}

.annual-report .indented {
  margin-left: 110px;
}

.annual-report h2 {
  font-size: 1.2em;
}

@media (max-width: 767px) {
  .annual-report > a {
    width: 60px;
  }
  .annual-report .indented {
    margin-left: 75px;
  }
  .annual-report h2 {
    margin: .5em 0;
  }
}

.annual-report.annual-report-featured {
  padding-top: 1em;
  margin-bottom: 2em;
}

.annual-report.annual-report-featured > a {
  width: 150px;
}

.annual-report.annual-report-featured .indented {
  margin-left: 180px;
}

.annual-report.annual-report-featured h2 {
  margin-bottom: .2em;
  font-size: 1.75em;
  font-weight: 300;
}

@media (max-width: 767px) {
  .annual-report.annual-report-featured {
    padding-top: 0;
  }
  .annual-report.annual-report-featured > a {
    width: 60px;
  }
  .annual-report.annual-report-featured .indented {
    margin-left: 75px;
  }
  .annual-report.annual-report-featured h2 {
    font-size: 1.33em;
  }
  .annual-report.annual-report-featured p {
    font-size: .95em;
  }
}

.board-member h2, .board-member h4 {
  margin: 0.2em 0 0.3em;
}

.board-member h2 {
  font-size: 1.66em;
}

.board-member h4 {
  color: #6e8393;
  font-size: .95em;
  font-weight: 300;
  margin-bottom: .75em;
}

.board-member img {
  max-width: 100%;
}

.board-member .label {
  text-transform: uppercase;
  float: right;
  padding: 0.4em 0.6em 0.4em;
}

@media (max-width: 767px) {
  .board-member .label {
    float: none;
  }
  .board-member > div:nth-child(2) {
    padding-left: 0;
  }
}

.stock-ticker {
  line-height: 1;
}

.stock-ticker .loaded, .stock-ticker .loading {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  transition: opacity 0.2s ease;
}

.stock-ticker .loaded {
  z-index: 2;
  opacity: 0;
}

.stock-ticker .loading {
  z-index: 1;
  opacity: 1;
}

.stock-ticker.loadComplete .loaded {
  opacity: 1;
}

.stock-ticker.loadComplete .loading {
  opacity: 0;
}

.stock-ticker .change-positive {
  color: #586976;
}

.stock-ticker .change-positive:before {
  color: #77bc1f;
  display: inline-block;
  margin-right: 3px;
  content: "";
  font-family: FontAwesome;
}

.stock-ticker .change-negative {
  color: #586976;
}

.stock-ticker .change-negative:before {
  color: #a71930;
  display: inline-block;
  margin-right: 3px;
  content: "";
  font-family: FontAwesome;
}

.stock-ticker .change-neutral {
  color: #586976;
}

.stock-ticker.panel-sidebar {
  min-height: 95px;
  position: relative;
}

.stock-ticker.panel-sidebar .loading, .stock-ticker.panel-sidebar .loaded {
  left: 10px;
  top: 10px;
}

.stock-ticker.panel-sidebar .panel-body {
  padding: 10px;
}

.stock-ticker.panel-sidebar .share-title {
  text-transform: uppercase;
  font-size: .725em;
  font-weight: 300;
  color: #858585;
  margin: 0.35em 0 1em 0;
}

.stock-ticker.panel-sidebar .price-value {
  font-size: 2.1em;
  color: #21282d;
  float: left;
  letter-spacing: -1px;
  line-height: .9;
}

.stock-ticker.panel-sidebar .price-value + div {
  margin-left: 95px;
}

.stock-ticker.panel-sidebar .loading-message {
  opacity: .5;
  padding: .5em 0;
}

.jumbotron .stock-ticker {
  height: 180px;
  width: 280px;
  float: right;
  text-align: center;
  margin-top: 30px;
  position: relative;
}

@media (max-width: 767px) {
  .jumbotron .stock-ticker {
    width: 100%;
    margin-top: 0;
  }
}

.jumbotron .stock-ticker h2 {
  font-weight: 200;
}

.jumbotron .stock-ticker .share-title {
  font-size: .8em;
  font-weight: 300;
  color: #858585;
  padding: 0.33em 0 1em 0;
  margin: 0.35em 0 1em 0;
  position: relative;
}

.jumbotron .stock-ticker .share-title:after {
  width: 90px;
  height: 1px;
  background: #DDD;
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -45px;
}

.jumbotron .stock-ticker .price-value {
  font-size: 2.25em;
  color: #21282d;
  letter-spacing: -1px;
}

.jumbotron .stock-ticker .change-row {
  padding: .25em 0;
}

.jumbotron .stock-ticker .loading-message {
  opacity: .5;
  padding: 2em 0;
}

.panel.corporateemailsubscribe {
  background: #f8f8f7;
}

@media (min-width: 768px) {
  .panel.corporateemailsubscribe fieldset, .panel.corporateemailsubscribe .contourNavigation {
    margin: 0;
  }
  .panel.corporateemailsubscribe .col-md-12 {
    padding: 0;
  }
  .panel.corporateemailsubscribe .form-group {
    float: left;
    clear: none;
  }
  .panel.corporateemailsubscribe .form-group .form-control {
    width: 100%;
  }
  .panel.corporateemailsubscribe .form-group.fullname {
    width: 50%;
    padding-right: 1em;
  }
  .panel.corporateemailsubscribe .form-group.email {
    width: 50%;
  }
}

.site-switcher .nav-tabs li.active a {
  border-top-color: #008fbe;
}

.header-container .navbar li.active > a {
  border-bottom-color: #008fbe;
}

@media (max-width: 767px) {
  .header-container .navbar li.active {
    border-left-color: #008fbe;
  }
  .header-container .navbar li.active > a {
    border-left-width: 0;
  }
}

.nav-pills.nav-stacked li.active a {
  border-left: 2px solid #008fbe;
}

.jumbotron.internal.tabbed li.active a {
  border-top: 3px solid #008fbe;
}

.btn-success {
  background-color: #008fbe;
  border-color: #00698b;
}

.btn-success:hover, .btn-success:active, .btn-success:focus, .btn-success:active:focus {
  background-color: #007ca5;
  border-color: #00698b;
}

.form-control:focus {
  border-color: #00698b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 90, 143, 0.6);
}

.contact-row a.tel {
  color: #008fbe;
}
